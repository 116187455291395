import { Helmet } from 'react-helmet'

const Tracking = () => {
  return (
    <Helmet>
      {/* Google tag (gtag.js) */}
      <script type="text/plain" data-usercentrics="Google Analytics 4" async src="https://www.googletagmanager.com/gtag/js?id=G-9HRG109653"></script>
      <script type="text/plain" data-usercentrics="Google Analytics 4">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-9HRG109653');
        `}
      </script>
    </Helmet>
  )
}

export default Tracking
